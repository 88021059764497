* {
    box-sizing: border-box;
}

:root {
    --header-size: 70px;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(assets/Roboto-Light.ttf);
}

html {
    background: #2a2a2a;
}

body {
    font-family: 'Roboto-Light', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans';
    color: #f1f1f1;
    background: #2a2a2a;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: cornflowerblue;
    cursor: pointer;
}

header {
    display: flex;
    width: 100%;
    height: var(--header-size);
    background: #2a2a2a;
}

header nav {
    margin: 0 auto;
}

header nav ul {
    display: flex;
    align-items: center;
    height: 100%;
    user-select: none;
}

header nav ul li {
    display: inline-block;
    margin: 0 .5rem;
    font-size: 1.25rem;
    cursor: pointer;
    transition: .2s;
}

header nav ul li:hover {
    transform: scale(1.05);
    font-weight: bolder;
}

main {
    display: flex;
    width: 100%;
    height: calc(100vh - var(--header-size));
    background-color: #161616;
}

main .content {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 100%;
}

/* main .content::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0, 0, 0, .5);
} */

main .content section {
    display: none;
    flex-direction: column;
    z-index: 3;
    width: 100%;
}

main .content .about {
    display: flex;
}

main .content .about .pre-title {
    font-size: 2rem;
}

main .content .about .title {
    font-size: 3rem;
}

main .content .about .subtitle {
    font-size: 2rem;
}

main aside {
    display: flex;
    justify-content: center;
    width: 20vw;
    background-color: #2a2a2a;
}

main aside .personal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
}

main aside .personal picture {
    margin-bottom: .5rem;
}

main aside .personal article>* {
    margin: .2rem 0;
}

main aside .personal article p,
h2 {
    font-size: 1.33rem;
}

main aside .personal article .name {
    font-size: 1.75rem;
    margin-bottom: .75rem;
}

main aside .photo {
    width: 180px;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;
}

main section {
    justify-content: center;
    align-items: center;
    max-width: 600px;
}

.separator {
    width: 100%;
    margin: .5rem 0;
    border-top: 1px solid rgba(255, 255, 255, .25);
    border-bottom: 1px solid rgba(255, 255, 255, .75);
}

.social-media ul li {
    margin: .33rem 0;
    font-size: 18px;
}

.information p {
    line-height: 1.75rem;
}

.description {
    font-size: 20px;
    line-height: 1.75rem;
}

.skills {
    width: 100%;
}

.skills .description-title {
    margin-top: 1rem;
    font-size: 22px;
}

.skills .tag-list .tag-list__item {
    display: inline-block;
    margin: .33rem .33rem .33rem 0;
    padding: .5rem;
    font-weight: 600;
}

.tag-list>.tag-list__item--blue {
    background: rgba(73, 89, 230, 0.5);
    color: white
}

.tag-list .tag-list__item--green {
    background: rgba(73, 230, 112, 0.5);
}

.tag-list .tag-list__item--purple {
    background: rgba(110, 73, 230, 0.5);
}

.tag-list .tag-list__item--orange {
    background: rgba(230, 144, 73, 0.5);
}

.tag-list .tag-list__item--yellow {
    background: rgba(230, 220, 73, 0.5);
}

.tag-list .tag-list__item--cyan {
    background: rgba(73, 217, 230, 0.5);
}

.tag-list .tag-list__item--lightblue {
    background: rgba(73, 154, 230, 0.5);
}

.tag-list .tag-list__item--gray {
    background: rgba(155, 155, 155, 0.5);
}

.tag-list .tag-list__item--red {
    background: rgba(230, 73, 73, 0.5);
}

.contact-form__input,
.contact-form__textarea {
    padding: .5rem;
    background-color: transparent;
    border: none;
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    color: white;
}

.contact-form__input {
    width: 50%;
    margin-right: -3px;
}

.contact-form__textarea {
    width: 100%;
}

.contact-form__button {
    all: unset;
    display: block;
    width: 95%;
    margin: .5rem auto;
    padding: .5rem;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, .5);
    border-radius: 7px;
    cursor: pointer;
}

.contact-form *:focus {
    border: 2px solid white;
}

.my-email {
    text-align: center;
}

.section-title {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
    align-self: flex-start;
}

.grecaptcha-badge {
    visibility: hidden;
}

.google-recaptcha {
    font-size: 10px;
    position: absolute;
    left: 4px;
    bottom: 4px;
    color: #7c7c7c;
}

.google-recaptcha a {
    color: #818181;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
    background: #000000;
}

::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
    background: #666666;
}

::-webkit-scrollbar-track:active {
    background: #333333;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

@media only screen and (max-width: 900px) {
    main aside .personal {
        width: 100%;
    }

    header nav {
        width: 100%;
        margin: 0;
        text-align: center;
    }

    header nav ul {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    main {
        flex-direction: column;
        height: auto;
    }

    main aside {
        width: 100%;
        height: 30vh;
    }

    main aside .photo {
        width: 120px;
        height: 120px;
    }

    main .content {
        width: 100%;
        min-height: calc(100vh - var(--header-size));
    }

    .about {
        width: 100%;
        text-align: center;
    }

    .about,
    .experience,
    .toolbelt,
    .contact {
        min-height: 60vh;
        padding: 1rem;
    }

    .personal {
        height: 450px;
    }
}